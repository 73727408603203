body {
  margin: 0;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* CSS for sweetalerts */
.swal-text {
  text-align: center;
  line-height: 1.5;
}

.swal-button--confirm {
  background-color: #008640;
}

.swal-button--cancel {
  background-color: #ef8a6f;
}

.loading {
  background-size: 100rem 100% !important;
  background-image: linear-gradient(
                  to right,
                  rgba($color: black, $alpha: 0.05) 0,
                  rgba($color: black, $alpha: 0.08) 15%,
                  rgba($color: black, $alpha: 0.05) 30%
  ) !important;
  background-color: rgba($color: black, $alpha: 0.01) !important;
  animation-name: loadingLeftToRight;
  animation-timing-function: linear;
  animation-duration: 2s;
  animation-iteration-count: infinite;

  @keyframes loadingLeftToRight {
    0% {
      background-position: -100rem 0;
    }
    100% {
      background-position: 100rem 0;
    }
  }
}
